import React from 'react';
import './css/App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import PartnerIdPage from "./components/PartnerIdPage";


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="partner/*" element={<PartnerIdPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
