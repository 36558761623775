import React from "react";
import './../css/App.css';
import ThirdPartyLogin from "./ThirdPartyLogin";
import AmazonEmployeeLogin from "./AmazonEmployeeLogin";

const LoginForm = () => {
    return (
        <div className="App">
            <AmazonEmployeeLogin />
            <ThirdPartyLogin />
        </div>
    );
};

export default LoginForm;