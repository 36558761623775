import React from "react";
import {Link} from "react-router-dom";
import './../css/App.css';

const ThirdPartyLogin = () => {
    return (
        <div className="App">
            <Link to="/partner" className="App-button">Third party Login</Link>
        </div>
    );
};

export default ThirdPartyLogin;