import React from "react";
import './../css/App.css';

const AmazonEmployeeLogin = () => {
    return (
        /*
            TODO - Hardcoding the IhmLogin Service URI for testing purposes. Remove this before prod and replace with
            config based routing with region and domain
         */
        <form action="https://ihmlogin-pdx.integ.amazon.com/login">
            <input type="submit" className="App-button" value="Amazon Employee Login"/>
        </form>
    );
};

export default AmazonEmployeeLogin;