import React from "react";
import '../css/partner.css';
import logo from "./logo.svg";

/*
    TODO - Hardcoding the IdentityBroker Endpoint for testing purposes. Remove this before prod and replace with
    config based routing with region and domain.
*/
const PartnerIdPage = () => {
    return (
        <div className="App">
            <img src={logo} className="App-logo" alt="Amazon" />
            <h4 className="App-login-prompt">
                Enter Your Partner Id
            </h4>
            <form action="https://alpha-iib.auth.us-west-2.amazoncognito.com/oauth2/authorize">
                <input type="hidden" name="client_id" value="3tk5lut0la0un3niogvgct940m"/>
                <input type="hidden" name="redirect_uri" value="http://localhost/"/>
                <input type="hidden" name="response_type" value="code"/>
                <input type="text" name="identity_provider" id="identity_provider" placeholder="Enter PartnerId"/>
                <input type="submit" className="partner-button" value="Next"/>
            </form>
        </div>
    );
};

export default PartnerIdPage;