import React from 'react';
import logo from './logo.svg';
import './../css/App.css';
import LoginForm from "./LoginForm";


const Home = () => {
    return (
        <div className="App">
            <img src={logo} className="App-logo" alt="Amazon" />
            <h4 className="App-login-prompt">
                Select your Login type
            </h4>
            <LoginForm />
        </div>
    );
};

export default Home;